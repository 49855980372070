import { Box, Flex, Text } from '@queimadiaria/ui'
import { FC } from 'react'

export const SpecialOfferFooter: FC = () => {
  return (
    <Flex
      sx={{
        borderTop: '1px solid #E8E8E8',
        borderBottom: '1px solid #E8E8E8',
        marginTop: '24px',
        padding: '24px 0',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: ['90%', '70%', '90%', '520px'],
          flexDirection: 'column',
          gap: 10,
        }}
      >
        <Text css={{}} variant='subheadline-3' as='p'>
          Ainda tem alguma dúvida?
        </Text>
        <Text variant='body-2' style={{ color: '#595769' }}>
          Sem problema! Se ainda tiver qualquer dúvida, basta enviar um Whatsapp
          para <b>(31) 9 9433-6135</b>. Agora vá em frente e finalize sua
          inscrição preenchendo o formulário acima e você receberá o acesso ao
          programa diretamente no seu email.
        </Text>
      </Box>
    </Flex>
  )
}
