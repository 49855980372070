import { Box, Flex, Grid } from '@queimadiaria/ui'
import { parseISO } from 'date-fns'
import { FC, useEffect } from 'react'

import { FetchAvailableCheckoutResponse } from 'src/features/plan/checkout/apis/checkoutApi'
import { useIsTabActive } from 'src/hooks/useIsTabActive'

import { CheckoutFormContainer } from '../components/CheckoutForm'
import { usePageInfoStore } from '../hooks/usePageInfoStore'
import { normalizeTimeSpecialOffer } from '../utils/specialOfferCheckout'

type TCheckout = {
  initAvailableCheckout?: FetchAvailableCheckoutResponse
}

export const SpecialOfferCheckoutScreen: FC<TCheckout> = () => {
  const {
    specialOffer,
    handleRedirectDefaultOffer,
    referenceTimeSpecialOffer,
  } = usePageInfoStore()
  const isTabActive = useIsTabActive()

  useEffect(() => {
    if (
      isTabActive &&
      specialOffer &&
      parseISO(specialOffer.endTime) <
        normalizeTimeSpecialOffer(new Date(), referenceTimeSpecialOffer)
    )
      handleRedirectDefaultOffer()
  }, [isTabActive, handleRedirectDefaultOffer])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box',
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          width: ['90%', '70%', '90%', '520px'],
        }}
      >
        <Grid columns={'100%'} gap='32px'>
          <CheckoutFormContainer />
        </Grid>
      </Box>
    </Flex>
  )
}
