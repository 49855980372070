/* eslint-disable @typescript-eslint/no-explicit-any */
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query'
import cookie from 'cookie'
import { GetServerSideProps } from 'next'

import { getRemoteConfig } from 'src/configs/remote'
import {
  CheckoutFeatureFlags,
  SubscriptionPeriodConfig,
} from 'src/configs/remote/remoteConfigs.types'
import { SpecialOfferCheckout } from 'src/features/checkout/typings/specialOfferCheckout'
import {
  getSpecialOfferCheckout,
  redirectSpecialOfferExpire,
  SaveSpecialOffer,
  SPECIAL_OFFERS_KEY,
} from 'src/features/checkout/utils/specialOfferCheckout'
import {
  checkoutApi,
  FetchAvailableCheckoutResponse,
} from 'src/features/plan/checkout/apis/checkoutApi'
import { couponGateway } from 'src/modules/coupon/providers/digital/gateway'
import { Coupon } from 'src/modules/coupon/types/coupon.types'
import { ValidateCouponPayload } from 'src/modules/coupon/types/gateway.types'
import { DigitalCheckout } from 'src/screens/DigitalCheckout'
import {
  buildQueryString,
  isValidIdParam,
} from 'src/screens/DigitalCheckout/helpers/checkout'

export type TCheckoutProps = {
  dehydratedState?: DehydratedState
  initAvailableCheckout?: FetchAvailableCheckoutResponse
  specialOfferCheckout?: SpecialOfferCheckout | null
  subscriptionPeriods: SubscriptionPeriodConfig[] | null
  featureFlags: CheckoutFeatureFlags | null
  couponFind?: Coupon | null
  productSellGroupId: number
}

export const getServerSideProps: GetServerSideProps = async context => {
  const planIdOrProductSellGroupId = context.params?.planId
  const queryParams = context.query

  if (!isValidIdParam(planIdOrProductSellGroupId))
    return {
      props: {},
      notFound: true,
    }

  try {
    const queryClient = new QueryClient()
    await Promise.allSettled([
      queryClient.fetchQuery({
        queryKey: ['is-legacy-checkout', planIdOrProductSellGroupId],
        queryFn: () =>
          checkoutApi.fetchIsLegacyPaymentProvider(
            planIdOrProductSellGroupId as string
          ),
      }),
      queryClient.fetchQuery({
        queryKey: ['brands', 'BR'],
        queryFn: () => {
          return checkoutApi.fetchCreditCardBrandsByCountryCode('BR')
        },
      }),
    ])

    const isLegacyCheckout = queryClient.getQueryData([
      'is-legacy-checkout',
      planIdOrProductSellGroupId,
    ])

    const availableCheckout = await queryClient.fetchQuery({
      queryKey: [
        'available-checkout',
        isLegacyCheckout,
        planIdOrProductSellGroupId,
      ],
      queryFn: () => {
        if (!planIdOrProductSellGroupId) return

        return checkoutApi.fetchAvailableCheckout(
          planIdOrProductSellGroupId as string,
          (queryParams.offerId as string) || undefined,
          queryParams.recoveryId as string
        )
      },
    })

    const { getSpecialOfferById, subscriptionPeriods, featureFlags } =
      await getRemoteConfig()

    const isSetHideBillet =
      !availableCheckout?.product.paymentMethods.includes('credit_card')

    const isHideBillet = queryParams?.hideBillet === '0'

    const specialOfferConfig = !!availableCheckout?.offer.id
      ? getSpecialOfferById(Number(availableCheckout.offer.id))
      : null

    let specialOfferCheckout: SpecialOfferCheckout | null = null

    if (specialOfferConfig) {
      const cookieSpecialOffer = context.req.cookies[SPECIAL_OFFERS_KEY]

      const savedSpecialOffers: SaveSpecialOffer[] = !!cookieSpecialOffer
        ? JSON.parse(cookieSpecialOffer)
        : []

      const saveNewSpecialOffer = (saveSpecialOffer: SaveSpecialOffer) =>
        context.res.setHeader('Set-Cookie', [
          cookie.serialize(
            SPECIAL_OFFERS_KEY,
            JSON.stringify([...savedSpecialOffers, saveSpecialOffer])
          ),
        ])

      specialOfferCheckout = getSpecialOfferCheckout(
        specialOfferConfig,
        savedSpecialOffers,
        saveNewSpecialOffer
      )

      if (!specialOfferCheckout) {
        return redirectSpecialOfferExpire(specialOfferConfig.redirectUrl)
      }
    }

    const codeCouponParam = queryParams?.coupon
    let couponFind: Coupon | null = null

    if (!!codeCouponParam && availableCheckout?.offer.id) {
      try {
        const code = String(codeCouponParam)
        const payload: ValidateCouponPayload = {
          code,
          productSellGroupId: Number(planIdOrProductSellGroupId),
          offerId: Number(availableCheckout?.offer.id),
          document: null,
          email: null,
        }

        const couponResponse = await couponGateway.validateCoupon(payload)
        if (couponResponse.success) {
          const destinationOffer =
            await couponGateway.getOfferDestinationCoupon(
              couponResponse.destinationOfferId
            )
          if (destinationOffer) {
            couponFind = {
              couponCode: code,
              id: couponResponse.id,
              discountAmount: couponResponse.discountAmount,
              purchaseTypeAllowed: couponResponse.purchaseTypeAllowed,
              destinationOffer,
            }
          }
        }
      } catch (err) {
        console.error(err)
      }
    }

    if (!isHideBillet && isSetHideBillet) {
      return {
        props: {
          initAvailableCheckout: availableCheckout,
          specialOfferCheckout,
          couponFind,
          productSellGroupId: Number(planIdOrProductSellGroupId),
          subscriptionPeriods,
          featureFlags,
        },
        redirect: {
          destination: `/checkout/${planIdOrProductSellGroupId}/${buildQueryString(
            { hideBillet: '0', ...queryParams }
          )}`,
        },
      }
    }

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
        initAvailableCheckout: availableCheckout,
        specialOfferCheckout,
        couponFind,
        productSellGroupId: Number(planIdOrProductSellGroupId),
        subscriptionPeriods,
        featureFlags,
      },
    }
  } catch (error: any) {
    const isNotFound = error?.status === 404
    const offerId = error?.json?.response?.offerId
    const productSellGroupId = error?.json?.response?.productSellGroupId
    const queryString = buildQueryString(queryParams, offerId)

    return {
      props: {},
      redirect: isNotFound
        ? {
            statusCode: 302,
            destination: `/checkout/${process.env.FALLBACK_PLAN_ID}`,
          }
        : {
            statusCode: 308,
            destination: `/checkout/${productSellGroupId}${queryString}`,
          } ?? { statusCode: 302, destination: '/500' },
    }
  }
}

const CheckoutByIdPage = (props: TCheckoutProps) => {
  return <DigitalCheckout {...props} />
}

export default CheckoutByIdPage
