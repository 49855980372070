import { Box, Flex, Text } from '@queimadiaria/ui'
import { FC } from 'react'

export const Footer: FC = () => {
  return (
    <Flex
      sx={{
        borderTop: '1px solid #E8E8E8',
        borderBottom: '1px solid #E8E8E8',
        margin: [
          '24px 0 24px 0',
          '24px 0 24px 0',
          '24px 0 24px 0',
          '24px 0 48px 0',
        ],
        padding: ['0 0 16px 0', '0 0 16px 0', '0 0 16px 0', '16px 0 16px 0'],
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: ['28px', '28px', '28px', null],
          width: ['90%', '70%', '90%', '1005px'],
          flexDirection: ['column', 'column', 'column', 'row'],
        }}
      >
        <Text
          css={{
            '@media screen and (max-width: 991px)': {
              marginBottom: '8px',
            },
            marginRight: '26px',
          }}
          variant='subheadline-3'
          as='p'
        >
          Ainda tem alguma dúvida?
        </Text>
        <Text
          variant='body-2'
          css={{
            '@media screen and (min-width: 992px)': {
              width: '70%',
            },
            justifyContent: 'center',
          }}
        >
          Sem problema! Se ainda tiver qualquer dúvida, basta enviar um Whatsapp
          para <b>(31) 9 9433-6135</b>. Agora vá em frente e finalize sua
          inscrição preenchendo o formulário acima e você receberá o acesso ao
          programa diretamente no seu email.
        </Text>
      </Box>
    </Flex>
  )
}
