import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'

import { Footer } from 'src/features/checkout/components/Footer'
import { SpecialOfferFooter } from 'src/features/checkout/components/SpecialOfferFooter'
import { useCheckoutForm } from 'src/features/checkout/hooks/useCheckoutForm'
import { useCountdownDigital } from 'src/features/checkout/hooks/useCountdownDigital'
import { usePageInfoStore } from 'src/features/checkout/hooks/usePageInfoStore'
import { usePaymentMethodsActive } from 'src/features/checkout/hooks/usePaymentMethodsActive'
import { Checkout } from 'src/features/checkout/screens/Checkout'
import { SpecialOfferCheckoutScreen } from 'src/features/checkout/screens/SpecialOfferCheckoutScreen'
import { useCupomDigitalCheckoutStore } from 'src/features/CupomDigitalCheckout/hooks/useCupomDigitalCheckoutStore'
import { UnknownServiceErrorMessage } from 'src/features/layout/components/UnknownServiceErrorMessage'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useAvailableCheckout } from 'src/features/plan/checkout/hooks/useAvailableCheckout'
import { useClientGeolocation } from 'src/features/plan/checkout/hooks/useIpAdressClient'
import { TCheckoutProps } from 'src/pages/checkout/[planId]'
import { QDHeadPage } from 'src/templates/components/HeadPage/QDHeadPage'

import { PaymentDigitalCheckoutProvider } from './hooks/modules/payment/PaymentDigitalCheckoutProvider'
import { DigitalCheckoutProvider } from './Providers/DigitalCheckoutProvider'

export function DigitalCheckout({
  couponFind,
  initAvailableCheckout,
  specialOfferCheckout,
  productSellGroupId,
  subscriptionPeriods,
  featureFlags,
}: TCheckoutProps) {
  const { isNoBanners } = useCheckoutParamsFromUrl()
  const { isError } = useAvailableCheckout()
  const form = useCheckoutForm()

  usePaymentMethodsActive()
  useClientGeolocation()
  const { init: iniPageInfo, specialOffer } = usePageInfoStore()
  const { setSelectedCupomDigitalCheckout } = useCupomDigitalCheckoutStore()

  useEffect(() => setSelectedCupomDigitalCheckout(couponFind || null), [])

  iniPageInfo({
    specialOfferCheckout: specialOfferCheckout || null,
  })

  const countdown = useCountdownDigital()

  if (isError || !productSellGroupId) return <UnknownServiceErrorMessage />

  return (
    <DigitalCheckoutProvider
      subscriptionPeriods={subscriptionPeriods}
      featureFlags={featureFlags}
      productSellGroupId={productSellGroupId}
    >
      <FormProvider {...form}>
        <PaymentDigitalCheckoutProvider>
          {(!specialOfferCheckout || specialOffer) && countdown}
          {!specialOfferCheckout ? (
            <>
              <QDHeadPage />
              <Checkout initAvailableCheckout={initAvailableCheckout} />
              {!isNoBanners && <Footer />}
            </>
          ) : (
            specialOffer && (
              <>
                <QDHeadPage />
                <SpecialOfferCheckoutScreen />
                {!isNoBanners && <SpecialOfferFooter />}
              </>
            )
          )}
        </PaymentDigitalCheckoutProvider>
      </FormProvider>
    </DigitalCheckoutProvider>
  )
}
