import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'

import { useProduct } from 'src/features/plan/product/hooks/useProduct'

import { checkoutAtoms } from '../atoms/checkoutAtoms'

export function usePaymentMethodsActive() {
  const { product } = useProduct()
  const [, setIsCreditCardInactive] = useAtom(
    checkoutAtoms.isCreditCardInactive
  )

  const handleIsCreditCardInactive = useCallback(() => {
    const checkIfTheCreditCardIsInactive =
      product?.paymentMethods.includes('credit_card') === false

    setIsCreditCardInactive(checkIfTheCreditCardIsInactive)
  }, [product, setIsCreditCardInactive])

  useEffect(() => {
    handleIsCreditCardInactive()
  }, [product])
}
