import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'

import { checkoutApi } from '../apis/checkoutApi'
import { ClientGeolocationDetails } from '../typings/clientGeolocationTypings'

export async function useClientGeolocation() {
  const [, setClientGeolocation] = useAtom(checkoutAtoms.clientGeolocation)
  const { data } = useQuery({
    queryKey: ['clientGeolocation'],
    queryFn: () => checkoutApi.getClientGeolocation(),
  })

  useEffect(() => {
    setClientGeolocation(data as ClientGeolocationDetails)
  }, [data])
}
